import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { usePlans } from "../hooks/usePlans"
import { graphql, Link } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import CompareHeader from "../components/compareComponents/CompareHeader"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import OperatorImage from "../components/images/OperatorImage"
import FourG from "../assets/fourg.svg"
import FiveG from "../assets/fiveg.svg"
import ThreeG from "../assets/connectivity.svg"
import styled from "styled-components"
import { AdserviceCompare } from "../components/CompareAdsense"

const Icon4g = styled(FourG)`
  width: 200px;
  height: 200px;
  margin: 0px auto;
`
const Icon3g = styled(ThreeG)`
  width: 200px;
  height: 200px;
  margin: 0px auto;
`
const Icon5g = styled(FiveG)`
  width: 200px;
  height: 200px;
  margin: 0px auto;
`
const FrontPage = ({ data }) => {
  const plansHook = usePlans(data.allPlansJson.nodes)

  return (
    <GlobalStyles>
      <MetaDetails
        title="Paras puhelinliittymä vertailu 2020 - Vertaa ja säästä!"
        desc="Valitse paras puhelinliittymä vertailemalla. Vertaa puhelinliittymät netillä tai ilman nettiä nopeasti ja helposti täältä. Vaihda halvempaan ja säästä!"
      />
      <CompareHeader
        plansHook={plansHook}
        title={"Puhelinliittymä vertailu 2020"}
        pageName={"puhelinliittymavertailu"}
        slogan="Vertaile paras puhelinliittymä vain 3 kysymyksellä"
      />
      <AdserviceCompare />
      <RowContainer title={"Tutustu myös seuraaviin vertailuihin:"}>
        <Button link={"/lapselle/"} title={"Puhelinliittymä lapselle"} />
        <Button
          link={"/ilman-nettia/"}
          title={"Puhelinliittymä ilman nettiä"}
        />
        <Button link={"/liittymatarjous/"} title={"Liittymä tarjoukset"} />
        <Button link={"/mobiililaajakaista/"} title={"Mobiililaajakaista"} />
        <Button link={"/prepaid/"} title={"Prepaid"} />
      </RowContainer>
      <Container>
        <Header2>
          Kattava puhelinliittymä vertailu – kaikki rajattomista, edullisista ja
          kuukausimaksuttomista liittymistä
        </Header2>
        <TextArea>
          <b>Puhelinliittymien vertaileminen kannattaa aina</b>, sillä voit
          saada halvemmalla hinnalla samat ominaisuudet tai jopa enemmän, kunhan
          vain perehdyt liittymiin etukäteen. Tämä artikkeli auttaa sinua
          liittymän valinnassa. Markkinoilla on tarjolla esimerkiksi rajattomia
          ja kuukausimaksuttomia liittymiä, kuten myös halpoja ja
          ominaisuuksiltaan riisuttuja puhelinliittymiä. Operaattorit
          kilpailevat liittymillä jatkuvasti, joten hinnat ja ominaisuudet
          muuttuvat runsaasti ja nopealla tahdilla. Tämän on kuluttajalle
          erinomainen asia, sillä näin puhelinkustannuksissa voidaan päästä
          huokeaan hintaan. Kuluttajan täytyy vain olla valmis vaihtamaan
          liittymää, mikä on onneksi tehty nykyaikana helpoksi, eikä numeronkaan
          tarvitse vaihtua. Liittymätarjoukset ovat houkuttelevia, mutta niiden
          voimassaoloaika kannattaa ottaa selville. Kun voimassaoloaika loppuu,
          nousevat niiden hinnat takaisin normaalille tasolleen, joka saattaa
          olla huomattavasti enemmän kuin olisit valmis maksamaan. Jos aiot
          hankkia uusimman ja nopeimman liittymän, tarkista myös tukeeko
          puhelimesi kaikkia hienoja ominaisuuksia, joista olet maksamassa.
          Vaikka verkossa kilpailuttaminen on helppoa, kannattaa joskus käydä
          operaattorin valtuuttamien myyjien kanssa paikan päällä kysymässä
          sopivaa tarjousta.
        </TextArea>
        <TextArea>
          Kuluttajaviranomainen on kuluttajan puolella, ja nykyään operaattoria
          voi vaihtaa milloin haluaa. Määräaikaiset sopimukset eivät tarkoita
          sitä, että niissä pitäisi pysyä niiden päättymiseen asti. Samoin
          puhelinten osamaksusopimukset eivät sido matkapuhelinoperaattorin
          diileihin. Suomessa ei ole yleensä sim-lukittuja puhelimia, joten
          operaattorin vaihtaminen on vaivatonta. Näin lupailevat jopa
          operaattoreiden omat myyjät, joille nopeat vaihtamiset voivat olla
          myös valttikortti. Kilpailu on kovaa hinnoissa ja ominaisuuksissa,
          joten kuluttaja hyötyy tästä kaikkein eniten. Aina kannattaa kysyä
          lisää.
        </TextArea>
        <Header3>
          Edullisimmat matkapuhelinliittymät netillä tai ilman nettiä
        </Header3>
        <TextArea>
          Edullisimmat liittymät eivät aina tarkoita sinulle sopivinta
          liittymää. Joskus on mahdollista että ilmoitettu hinta ei kerro koko
          totuutta, vaan liittymien niin sanotut piilokulut saattavat olla
          yllättävänkin korkeita liittymän todellisen käytön aikana. Jos puhut
          paljon, kannattaa tarkistaa kuinka paljon liittymässä on ilmaista
          puheaikaa. Samaa kannattaa seurata myös silloin, kun lähetät paljon
          tekstiviestejä. Yleisesti ottaen suomalaisten tekstiviestien
          lähettäminen on vähentynyt runsaasti, uusien pikaviestisovellusten
          vallatessa tilaa tekstiviesteiltä. WhatsApp, Facebook Messenger ja
          Telegram kuuluvat suosituimpiin pikaviestisovelluksiin, jotka vaativat
          internet-yhteyden toimiakseen. Usein suurin kulu
          matkapuhelinliittymissä tuleekin internetin käytöstä. Näiden
          sovellusten vuoksi{" "}
          <Link to="/ilman-nettia/">puhelinliittymä ilman nettiä</Link> ei kuulu
          lähes minkään operaattorin valikoimaan.
        </TextArea>
        <TextArea>
          Suomessa on totuttu rajattomaan internetkäyttöön. Muualla maailmassa
          rajaton tiedonsiirto ei ole niin yleistä, ja esimerkiksi
          Etelä-Euroopassa edelleen yleisimmät matkapuhelinliittymät sisältävät
          rajoitetun määrän gigatavuja internetin käyttöön ja tiedonsiirtoon.
          Myös Suomessa on tarjolla liittymiä rajoitetulla internetin käytöllä
          ja usein nämä ovatkin edullisimmasta päästä. Kun rajoitetut gigatavut
          ylittyvät, voi liittymän kulut kasvaa huomattavastikin, jos käyttää
          mobiilitiedonsiirtoa internetin käytössä jatkuvasti.
        </TextArea>
        <TextArea>
          Suomessa halvimmat puhelinliittymät ovat kuukausihinnaltaan tällä
          hetkellä kuusi euroa. Kuukausihintaisten liittymien lisäksi etukäteen
          maksettavat prepaid-liittymät ovat hyvin suosittuja varsinkin nuorten
          keskuudessa, sillä tällaisissa liittymissä ei tule ikäviä yllätyksiä
          ylisuurten puhelinlaskujen suhteen.{" "}
          <b>
            Rajattomat liittymät tarkoittavat yleensä rajatonta tiedonsiirtoa
          </b>
          , mutta ne voivat myös viitata rajattomaan puheeseen tai
          tekstiviesteihin. Tällaiset liittymät maksavat kuukaudessa yleensä
          kahdenkymmenen euron molemmin puolin, mutta liittymien ominaisuudet
          riittävät usein kuluttajan tarpeisiin, eivätkä kustannukset nouse
          kuukausimaksusta yhtään enempää. Tavallisen kuluttajan lisäksi
          yrityksillä on tarve kilpailuttaa puhelinliittymänsä.
        </TextArea>
        <TextArea>
          Monet yrittäjät valitsevat kuluttajille tarkoitetun liittymän, sillä
          ne ovat edelleen yleensä halvempia. Yrittäjille tarkoitetuissa
          liittymissä on kuitenkin monenlaisia etuja, joiden vuoksi kannattaa
          pohtia, maksaako joitakin euroja enemmän oman yrityksen tarpeita
          ajatellen. Puhelinliittymien vertailuun on useita tapoja. Helpointa on
          käyttää tällaiseen tarkoitettuja nettisivuja, mutta ne eivät aina
          kerro koko totuutta. Joskus on parempi kääntyä operaattoreiden puoleen
          ja kertoa omista vaatimuksista. Tällöin voi löytyä helpommin juuri se
          sopivan halpa liittymä.
        </TextArea>
        <Header3>Rajattomat liittymät</Header3>
        <TextArea>
          Rajattomat liittymät tarkoittavat yleensä rajatonta internetin käyttöä
          samalla kuukausihinnalla. Erot rajattomassa internetin käytössä ovat
          liittymien nopeuksissa. Pienimmät nopeudet ovat yleensä 0,256
          megabittiä sekunnissa, joka riittää satunnaiseen netissä surffailuun,
          uutisten lukemiseen ja sähköpostien lähettämiseen. Myös
          pikaviestisovellukset toimivat kohtuullisesti, jos ei lähetä ja
          vastaanota runsaasti kuvia ja videoita. Esimerkiksi Telian Rajaton
          Lite 0,2 M maksaa kirjoitushetkellä 7,90 euroa kuussa ja tässä
          liittymässä on rajaton netti 0,256 megabittiä sekunnissa. Puhelut
          maksavat kyseisessä liittymässä 7 senttiä minuutilta.
        </TextArea>
        <TextArea>
          Samoin tekstiviestit maksavat saman verran kappaleelta. EU- ja
          ETA-maissa tiedonsiirron käyttökatto on kolme gigaa kuussa. Elisalla
          vastaava liittymä on <b>Saunalahti Tarkka Mini</b>, jonka
          tiedonsiirtonopeus on sama kuin Telian Rajaton Lite M -liittymässä.
          Saunalahti Tarkka Minin puhelu- ja tekstiviestihinnat ovat käytännössä
          samat eli 0,069 €/min ja viestit 0,069 €/kpl. EU- ja ETA -alueilla
          tiedonsiirron käyttökatto on 2 gigaa kuussa. Elisan avausmaksu on
          hieman korkeampi kuin Telialla. Myös DNA:n vastaava on käytännössä
          samanhintainen.
        </TextArea>
        <TextArea>
          Rajattomien liittymien nopeudet ovat kasvaneet matkapuhelinliittymissä
          huimaa vauhtia ja nopeimpien liittymien luvataan pääsevän jopa 600
          megabitin sekuntinopeuteen. Todellisuus on kuitenkin se, että
          huippunopeuksiin päästään harvoin, ja keskimääräiset nopeudet ovat
          paljon pienemmät. <b>Telian Rajaton 450 M</b> on huippunopeudeltaan
          450 megabittiä sekunnissa. Rajattoman internetin lisäksi myös puhelut
          ja viestit kuuluvat rajattomasti samaan hintaan, joka on 39,90 €
          kuussa – mutta vain 12 kuukauden ajan, jonka jälkeen hinta nousee
          viidellä eurolla.<b> DNA Max 4G Rajaton</b> -liittymässä ei
          suoranaisesti luvata tiettyä nopeutta, vaan sen kerrotaan pääsevän
          4G-verkon maksiminopeuteen. Liittymällä ei ole määräaikaa, ja sen
          hinta on 49,90 euroa kuussa. Elisa tarjoaa tällä hetkellä ainoana
          operaattorina 5G:tä tukevaa Saunalahti Huoleton 5G 1000M -liittymää.
          49,90 eurolla kuussa Elisa lupaa 4G:lläkin 600 megabitin
          sekuntinopeutta ja 5G:llä maksimissaan gigabitin sekuntinopeutta. Moin
          Rajaton Turbo pääsee nopeudeltaan vain 300 Mbit/s -huippunopeuteen,
          mutta hintakin on huomattavasti huokeampi kuin isommilla
          operaattoreilla, vain 23 euroa kuussa. Nämä liittymät sisältävät
          normaalist myös roaming dataa ulkomailla Eu-maissa. Voit lukea lisää
          roamingista{" "}
          <a href="http://europa.eu/youreurope/citizens/consumers/telecoms-internet/mobile-roaming-costs/index_fi.htm">
            täältä. (englanniksi)
          </a>
        </TextArea>
        <Header3>
          Kuukausimaksuttomat liittymät netillä tai ilman nettiä
        </Header3>
        <TextArea>
          Kuukausimaksuttomat matkapuhelinliittymät ovat yleensä niin sanottuja
          prepaid-liittymiä. Prepaid-liittymille ladataan rahaa etukäteen,
          jolloin kuluttaja maksaa vain sen tosiasiallisesta käytöstä.
          Prepaid-liittymään ladataan saldoa verkossa. Saldotilannetta voi myös
          seurata verkossa tai mobiililaitteessa. Prepaid-liittymän etuna ovat
          muun muassa puhelinkulujen hallinta, koska liittymä maksetaan
          etukäteen. Tällöin ei tule yllättäviä laskuja tai muita maksuja.
        </TextArea>
        <TextArea>
          Prepaid-liittymä on hyvä myös tilapäiseen käyttöön, jos
          puhelinliittymää ei ole tarkoitus jostain syystä jättää käyttöön
          tietyn ajan jälkeen. Vaikka prepaid-liittymissä ei ole
          kuukausimaksuja, niitä joutuu lataamaan noin 6-12 kuukauden välein
          liittymien ylläpitämiseksi. Prepaid-liittymiä ja niiden lataamisen
          tarkoitettuja seteleitä on saatavilla esimerkiksi R-kioskeilla ja
          kaupoissa. Prepaidin lataaminen onnistuu verkon ja lataussetelin
          lisäksi Otto-pankkiautomaateilla. Prepaid-liittymien hinnat alkavat
          olla nykyään jo vertailukelpoisia tavallisten liittymien kanssa, jos
          vertaa erityisesti puheluiden ja tekstiviestien hintoja.
          Prepaid-liittymissä on usein rajoituksia verrattuna tavallisiin
          puhelinliittymiin. Prepaid-liittymiä ei voi käyttää esimerkiksi
          mobiilimaksamiseen. Prepaid-liittymät voivat olla sopivia vaikka
          teini-ikäiselle tai lapselle, joka vasta opettelee puhelimen käyttöä.
        </TextArea>
        <TextArea>
          DNA:n prepaid-liittymän 4,90 euron hintainen aloituspakkaus sisältää 5
          euroa saldoa ja liittymän puhelut ja tekstiviestit maksavat 0,066 €
          minuutilta tai kappaleelta. Tiedonsiirto maksaa liittymässä 99 senttiä
          jokaiselta vuorokaudelta, jona tiedonsiirtoa käyttää. Telian
          prepaid-liittymän puhelu- ja viestikulut ovat samat kuin DNA:lla. Myös
          kertamaksu on saman hintainen kuin DNA:lla, mutta käyttäjällä on tämän
          jälkeen seitsemän euroa käytettävissä.{" "}
          <b>Tiedonsiirto on samoin maksimissaan 99 senttiä vuorokaudelta</b>.
          Elisan Saunalahti Prepaidin aloitus maksaa 5,90 euroa. Puhelu- ja
          tekstiviestikulut ovat DNA:n ja Elisan kanssa samalla viivalla, kuten
          myös tiedonsiirtomaksut maksimissaan per vuorokausi. Lue lisää Elisan
          Prepaid liittymistä osoitteessa{" "}
          <a href="https://elisa/.fi/kauppa/puheliittymat/prepaid/">Elisa.fi</a>
        </TextArea>
        <TextArea>
          Saunalahti Prepaidin internetiä voi käyttää myös 0,066 €
          megatavuhintaan. Yhteistä jokaisen operaattorin prepaid-liittymille on
          se, että numerot ovat prepaid-liittymillä aina oma, eikä esimerkiksi
          vanhaa numeroa voi siirtää prepaid-liittymän käyttöön. DNA:n, Elisan
          ja Telian prepaid-liittymillä on myös yleensä omat
          operaattoritunnukset, eli prepaid-liittymät voi erottaa
          puhelinnumeroiden kolmesta ensimmäisestä numerosta. Nykyään kuitenkin
          samoja operaattoritunnuksia voi olla myös tavallisten liittymien
          käytössä.
        </TextArea>
        <TextArea>
          Prepaid-liittymien lisäksi muita kuukausimaksuttomia liittymiä on
          vähän tarjolla. Vielä on kuitenkin tarjolla esimerkiksi Saunalahti
          Latausliittymä. Se on tavallinen puhelinliittymä, jonka käytöstä
          maksetaan prepaid-liittymien tapaan etukäteen. Perinteisistä
          Prepaid-liittymistä eroten latausliittymiin voi vaihtaa numeron eri
          operaattorilta. Latausliittymästä ei kuitenkaan voi vaihtaa
          tavalliseen matkapuhelinliittymään niin, että puhelinnumero säilyisi
          ennallaan. Latausliittymissä ladataan saldoa prepaid-liittymien tapaan
          ja saldosta vähennetään puheluihin ja tekstiviesteihin käytetyt kulut.
          Latausliittymä on voimassa kaksi vuotta tilauksesta.
        </TextArea>
        <TextArea>
          Latausliittymien hinnassa ei ole mukana internet-tiedonsiirtoa, vaan
          siitä joutuu maksamaan erikseen. Tiedonsiirron hinta latausliittymässä
          on 0,66 euroa vuorokaudelta. <b>Telia Dot -liittymä </b>on myös
          kuukausimaksuton liittymä, jossa maksetaan vain siitä mitä käyttää.
          Telia Dotin hintakattona on lisäksi 25 euroa, joten se on turvallinen
          vaihtoehto prepaid-liittymien ohelle, jos haluaa tietää etukäteen
          minkä verran puhelinliittymästään maksaa. Telia Dot sisältää
          rajattoman 4G-datan, puhelut ja tekstiviestit. 4G-verkon maksiminopeus
          100 Mbit/s. Aiemmin myös Globetel on tarjonnut kuukausimaksutonta
          liittymää, mutta kirjoitushetkellä yhtiö tarjoaa vain
          kuukausimaksullisia matkapuhelinliittymiä.
        </TextArea>
        <Header3>Puhelinliittymiä yrityksille</Header3>
        <TextArea>
          Tavallisten kuluttajille suunnattujen puhelinliittymien lisäksi
          tarjolla on runsaasti liittymiä yrityksille. Jopa yksinyrittäjän
          kannattaa vertailla kuluttajille ja yrittäjille suunnattujen
          liittymien välillä. Usein yrittäjien kannattaa hankkia sellainen
          liittymä, jossa on paljon puheaikaa yrityksen asioiden hoitamiseen.
          Jos yrittäjä matkustaa paljon, on yrittäjille suunnatuissa liittymissä
          usein rajaton netti esimerkiksi pohjoismaissa ja Baltian maissa.
          Jotkut operaattorit tarjoavat runsaasti datasiirtoa samaan hintaan
          myös muualla maailmassa, joten kansainväliselle yritykselle tämä on
          tärkeä ominaisuus.
        </TextArea>
        <TextArea>
          Kuten kuluttaja-asiakkaan, on yrittäjänkin paras miettiä käyttötarve
          ensiksi ja kilpailuttaa sitten liittymä sen mukaan, mitä palveluja ja
          minkä verran tulee todennäköisesti käytettyä. Vaikka yrittäjille
          suunnatut liittymät ovat usein kalliimpia kuin kuluttajille
          tarkoitetut, saa niillä myös enemmän palvelua ja jopa nopeutta
          datasiirtojen suhteen. Operaattorit antavat usein enemmän kaistaa
          yritysliittymille, vaikka sitä ei suoraan verkkosivuilla sanotakaan.
          Yritysliittymille luvatut nopeudet pitävät kutinsa kuitenkin useammin
          kuin kuluttajaliittymillä. Tämä johtuu siis siitä, että ruuhkaisina
          datasiirtoaikoina yrittäjäliittymille annetun kaistan ansiosta
          yhteydet toimivat nopeammin ja varmemmin.
        </TextArea>
        <TextArea>
          <b>Telia Pro EU S -liittymä </b>sisältää 5 000 minuuttia puheaikaa, 50
          kappaletta tekstiviestejä kuukaudessa, rajattoman netin Pohjoismaissa
          ja Baltiassa sekä 10 gigatavua datasiirtoa EU-maissa. Netin
          maksiminopeus on 10 megabittiä sekunnissa sekä lataukseen että
          lähetykseen. Tämä riittää mainiosti peruskäyttöön, jolla lataa vielä
          kuvia ja videoitakin sosiaaliseen mediaan. Hintaa paketilla on 21,95
          euroa kuussa. <b>Elisan Yrityspaketti 10 M</b> on teknisesti
          samanlainen kuin Telian vastaava, mutta se sisältää myös Elisan
          WiFi-puheluominaisuuden. Hintaa paketilla on 23,95 euroa kuussa.
          WiFi-puhelulla voi soittaa ja vastaanottaa puheluita langattoman
          verkkoyhteyden avulla. WiFi-puhelu sisältyy Yrityspaketti-liittymiin.
          DNA Business Rajaton 4G 50M Suomi on halvin DNA:n yritysliittymä,
          jonka saa käyttöön 23 eurolla kuukautta kohden. Se sisältää kotimaassa
          ja EU/ETA-maissa rajattomasti puheaikaa, 1000 kappaletta
          tekstiviestejä ja kotimaassa rajattoman netin 50 megabitin
          sekuntinopeudella. Liittymä ei sisällä minkäänlaista EU-datapakettia
          eikä rajatonta dataa Pohjolassa tai Baltiassa. DNA:n paketti erottuu
          edukseen kuitenkin nopeudellaan. Operaattorien yritysliittymiin on
          tarjolla myös runsaasti lisäpalveluja.
        </TextArea>
        <Header2>Miten vertailla halpa puhelinliittymä</Header2>
        <TextArea>
          Halpaa puhelinliittymää vertaillessa kannattaa miettiä ensiksi omat
          minimitarpeet liittymälle, koskivat ne sitten puhetta, tekstiviestejä
          tai datasiirtoa. Tämän jälkeen on helpompaa löytää sopiva
          puhelinliittymä tarvittavilla ominaisuuksilla. Operaattorit listaavat
          verkkosivuillaan halvimmat liittymät selkeästi, mutta liittymien
          ominaisuuksissa voi olla ratkaisevia eroja. Päätä pahkaa ei kannata
          siis halvinta valita, vaikka hinta olisikin houkutteleva. Niin sanotut
          piilokustannukset voivat nostaa hintaa ylös yllättävän paljon.
          Halvimmissa liittymissä on karsittu ominaisuuksista, mutta niitä saa
          käyttöön maksamalla erikseen.
        </TextArea>
        <TextArea>
          Erikseen ominaisuuksista maksamalla halvimman puhelinliittymän hinta
          saattaakin nousta korkeammaksi kuin markkinoilla ollut pakettihinta.
          Usein kannattaa olla suoraan operaattoriin yhteydessä ja pyytää
          tarjousta puhelinliittymästä, sekä mainita kilpailijan hinta
          liittymäpaketista, jota ollaan vertailemassa. Vaikka kuukausihinta ei
          välttämättä laskisikaan, saattaa kaupan päälle tulla hyviä
          ominaisuuksia tai vaikkapa maksutonta puheaikaa ja tekstiviestejä.
          Verkkosivuilla olevat hinnat eivät ole aina lopullisia totuuksia.
          Operaattoreilla on varaa lisätä mukavia ominaisuuksia liittymiin
          maksutta, vaikka sitten vain määräajaksi. Aina kannattaa kysyä!
        </TextArea>
        <TextArea>
          Halvimpien puhelinliittymien vertailuun on olemassa useita
          verkkosivuja, joihin tarvittavia ominaisuuksia tai hintakattoja
          syöttämällä ne etsivät kuluttajalle halvimman vaihtoehdon
          operaattoreiden tarjoamien puhelinliittymien paketeista. Tällaiset
          hintavertailu- ja hintaseurantaverkkosivut ovat käteviä, helppoja ja
          nopeita käyttää. Niiden avulla pääsee vähintäänkin kartalle yleisestä
          hintatasosta ja parhaimmillaan niiden avulla löytää sen sinulle
          sopivan liittymän. Verkkosivusta riippuen ne ottavat huomioon kolmen
          ison operaattorin lisäksi myös pienemmät alan toimijat Suomessa.
        </TextArea>
        <TextArea>
          <b>Moi Mobiili</b>ja Globetel ovat vähemmän tunnettuja operaattoreita,
          mutta niiltäkin löytyvät omat halvat puhelinliittymät. Moi Mobiili on
          tunnettu erityisen halvoista liittymistään, jonka kaltaisia muut eivät
          ole pystyneet tarjoamaan. Liittymät ovat toki ominaisuuksiltaan
          rajoitettuja, mutta kevyeen peruskäyttöön ne ovat hinnaltaan sopivia
          ohuemmallekin kukkarolle. Operaattoreiden työntekijöitä tapaa myös
          kauppojen käytävillä. Joskus kannattaa pysähtyä ja pyytää juuri sen
          hetkistä tarjousta. Usein myyjät tarjoavat liittymien päälle mukavia
          lahjoja tai maksuttomia ominaisuuksia, jolloin sinulle saattaa
          löytyäkin tarvitsemasi puhelinliittymä. Kaikkea tietoa ei ole verkossa
          tarjolla.
        </TextArea>
        <Header3>Mikä on 3G?</Header3>
        <Icon3g />
        <TextArea>
          3G on nimensä mukaisesti kolmannen sukupolven matkapuhelinteknologia.
          Kun muinoin matkapuhelimien teknologioista muistettiin vain lyhenteet
          NMT ja GSM, oli 3G jo tarkoitettu datasiirtoon, eli internetin
          käyttöön matkapuhelimissa. Uusi kolmannen sukupolven tekniikka oli
          siis paljon muutakin kuin puhetta ja tekstiviestejä. Euroopan yleisin
          3G-standardi on nimeltään UMTS. Muita tunnettuja on esimerkiksi
          Amerikasta lähtöisin oleva Edge, joka ei ole puhdasverinen
          3G-tekniikka vaan eräänlainen versio toisen sukupolven GSM-ajan
          tekniikasta.
        </TextArea>
        <TextArea>
          Suomi on ollut 3G:ssäkin edelläkävijä koko maailman mittapuulla.
          Liikenne- ja viestintäministeriö myönsi viime vuosituhannen
          loppupuolella UMTS-taajuudet suurimmille Suomessa toimiville
          operaattoreille. Ministeriö teki tämän ensimmäisenä maailmassa!
          Taajuudet tarkoittavat radiotaajuuksia, joten niitä ei ole riittämiin
          käytössä. Siksi eri maissa tarvitaankin jonkinlainen säädäntö sille,
          mitä taajuuksia kukakin operaattori saa käyttää ja mihin
          tarkoitukseen. Monissa muissa maissa radiotaajuudet ovat käytännössä
          huutokauppojen alaisia. Suomessa niitä säädetään kuitenkin liikenne-
          ja viestintäministeriön toimesta. 3G:n seuraajat ovat hyvin yleinen 4G
          sekä tuloillaan oleva 5G.
        </TextArea>
        <TextArea>
          Suomessa ei enää myydä erikseen 3G-matkapuhelinliittymiä, sillä 4G on
          lähes joka puolella Suomea käytössä. 3G:stä ei kuitenkaan ole
          luovuttu, sillä se voi pelastaa monet puhelut ja datasiirrot alueilla,
          jossa ei 4G toimi kunnolla. Puhelimen asetuksissa on yleensä
          oletuksena päällä ominaisuus, jonka avulla puhelin käyttää
          automaattisesti 3G-verkkoa, jos 4G:tä ei ole saatavilla. Suomessa tätä
          tapahtunee harvemmin (paitsi muutamilla harvaan asutuilla alueilla)
          mutta ulkomailla ominaisuus on varsin kätevä. Samoin markkinoilla
          olevat, 4G:llä markkinoitavat liittymät voivat käyttää edelleen 3G:tä,
          jos sille on tarvetta. Eräät halvimmat puhelimet käyttävät edelleen
          3G-verkkoa pääsääntöisesti. Esimerkiksi Kiinasta tilatut
          halpapuhelimet ovat usein 3G-puhelimia. Suomessa on totuttu nopeisiin
          yhteyksiin, mutta monissa muissa maissa 3G on edelleen arkipäivää.
          Kaikille onkin turvallisinta, ettei yhteys katkea vaikka se kaikkein
          nopein verkko ei olisikaan enää saatavilla. 3G saattaa tuntua
          lähihistorialta, varsinkin kun 5G:tä markkinoidaan jo Elisan
          liittymissä. Vanhoille verkoille on kuitenkin tarvetta edelleen.
        </TextArea>
        <Header3>Mikä on 4G?</Header3>
        <Icon4g />
        <TextArea>
          4G on neljännen sukupolven mobiiliteknologia, jonka avulla
          matkapuhelimista tuli lopulta todellisia viihdekeskuksia. 4G:n myötä
          datasiirtonopeudet kasvoivat niin paljon, että omalta puhelimelta
          pystyi streamaamaan elokuvia Netflixistä, HBO:sta ja muista
          elokuvapalveluista. Nyt 4G:n nopeudet ovat lähes itsestäänselvyys
          Suomessa, joka on ollut edelläkävijä mobiiliteknologioiden
          käyttöönotossa. 4G:n nopeudet vaihtelevat kuitenkin runsaasti ja
          vaikka yleisesti ottaen kyseenomainen tekniikka tarjoaa tarvittavan
          nopeuden monipuoliseen internetin käyttöön, eivät operaattoreiden
          ilmoittamat nopeudet ole aina läheskään luvatun kaltaisia. 4G-verkko
          kattaa lähes koko Suomen, joten sinällään yhteydet ovat kotimaassa
          kunnossa.
        </TextArea>
        <TextArea>
          Viime vuosina 4G-verkkoa on parannettu Lapissa ja Itä-Suomessa, joiden
          katvealueet ovatkin herättäneet närästystä paikallisissa asukkaissa.
          Kun verrataan 4G:n kattavuutta moniin muihin maihin, voidaan sanoa
          että Suomessa ollaan kuitenkin yleisesti ottaen hyvällä mallilla.
          Tästä huolimatta kannattaa tarkistaa edelleen myös operaattorin
          toimivuus alueella, jossa eniten tarvitset matkapuhelinliittymää.
          Vaikka hinta olisikin halvin toisella operaattorilla, on toimivuus
          kuitenkin aina tärkeämpää. Voit kysyä operaattorilta suoraan
          toimivuudesta tietyillä alueilla.
        </TextArea>
        <TextArea>
          Kaikki matkapuhelinliittymät ovat tällä hetkellä vähintään
          4G-liittymiä, mutta seuraaja 5G kurkkii jo nurkan takana. Elisa on
          kuitenkin kirjoitushetkellä ainoa operaattori, joka tarjoaa myös
          5G-liittymiä. 5G:n luvataan olevan ainakin kymmenkertainen
          nopeudeltaan 4G:n nähden, mutta useimpien kannattaa odotella kunnes
          uuden teknologian alkuvaiheen ongelmat on saatu ratkaistua. 4G:tä
          kehitetään edelleen pitkään, sillä siitäkään ei ole saatu vielä
          kaikkea irti.
        </TextArea>
        <TextArea>
          Lisäksi 4G-verkko on sen verran kattava, että 5G:llä kestää vuosia
          päästä samaan pisteeseen. Jos kuluttaja on kuitenkin niin sanottu
          varhainen käyttöön ottaja (early adopter), saattaa 5G-liittymän
          hankkiminen olla tarpeen, varsinkin jos hinta ei eroa ratkaisevasti
          4G-liittymästä. Tosiasia kuitenkin on, että 5G-liittymäkin joutuu
          käyttämään 4G:tä pitkän aikaa. 4G ei tarkoita pelkästään datasiirtoa,
          vaan 4G:tä käytetään myös puheluihin. Kun puhelin ei siirry 2G- tai
          3G-verkkoihin puhelun ajaksi, yhdistyvät puhelut näin ollen nopeammin.
          Lisäksi 4G-puhelut voivat tukea HD Voice -äänenlaatua, joka on
          laadukkaampi ja selkeämpi. HD Voice vaatii kuitenkin tuen molemmilta
          puheluiden osapuolilta.
        </TextArea>
        <Header3>5G on tulossa, mutta milloin?</Header3>
        <Icon5g />
        <TextArea>
          Maailman ensimmäiset kaupalliset 5G-verkot avattiin vuonna 2018
          Tampereella ja Tallinnassa Elisan toimesta. Operaattori on avannut
          tähän mennessä 5G-verkkoja Tampereelle, Helsinkiin, Jyväskylään ja
          Turkuun. Lisää verkkoja on luvassa vuoden 2020 aikana. Vuoden 2020
          aikana kuitenkin 5G-verkkojen ja -laitteiden käyttö yleistyy enemmän.
          5G-verkko lisää mahdollisuuksia internetin käyttöön matkapuhelimissa.
          4K- ja 360-videot toimivat paremmin mobiililaitteissa nopeamman
          internetin ansiosta. DNA ja Telia ovat puolestaan aloittaneet
          5G-testaukset vuonna 2017. Molemmat operaattorit lupailevat
          5G-päätelaitteiden yleistymistä samoin vuoden 2020 puolella.
        </TextArea>
        <TextArea>
          Kaikki operaattorit muistuttavat kuitenkin 4G:n vahvasta asemasta,
          joka jatkuu pitkään vielä 5G-huuman ollessa kuumimmillaan. Suurimmaksi
          osaksi operaattorit ylistävät 5G:tä asioissa, jotka eivät välttämättä
          liity niinkään matkapuhelinliittymiin. Aika siis näyttää, kuinka 5G
          mullistaa matkapuhelinliittymien maailman muuten kuin eittämättä
          paremmalla nopeudella ja datasiirron toimivuudella. 5G tarvitsee
          varmasti useita vuosia näyttääkseen lopulliset kyntensä. Halvinta
          puhelinliittymää haluavalle 5G-liittymät eivät todennäköisesti palvele
          vielä tarpeeksi niin ominaisuuksien, kuin hintojensakaan suhteen.
          Antaa ajan hiukan kulua!
        </TextArea>
        <Header2>Tarjousten aikaiset hinnat</Header2>
        <TextArea>
          Edullisimpia matkapuhelinliittymiä vertailtaessa kannattaa kuluttajan
          ottaa hinnassa huomioon tarjouksen kesto. Usein hinnat ovat voimassa
          vain tietyn ajan, kuten 12 kuukautta. Sen jälkeen liittymien hinnat
          nousevat automaattisesti niin sanottuun perushintaan, joka on
          tarjoushintaa korkeampi. Puhelinliittymien vertaileminen ja
          kilpailutus kannattaakin suorittaa vähintään kerran vuodessa, mutta
          puolen vuoden väleinkin on näinä päivinä aivan tavallinen tahti.
          Operaattorit eivät voi sitoa asiakkaitaan enää tietyksi ajaksi
          liittymien käyttöön kuten ennen, joten liittymän vaihtaminen on
          mahdollista vieläkin useammin.
        </TextArea>
        <Header4>Elisa</Header4>
        <OperatorImage imgName={"elisaoperaattori.jpg"} />
        <TextArea>
          Elisa on suomalainen tietoliikenne- ja digitaalisten palveluiden
          markkinajohtaja, joka on toiminut jo 136 vuotta. Elisalla on nyt yli
          2,8 miljoonaa asiakasta Suomessa, Virossa ja kansainvälisesti. Elisan
          Saunalahti -liittymien nimi juontavat juurensa Saunalahti-yritykseen,
          jonka Elisa osti vuonna 2005. Elisan suosituimmat tämän hetkiset
          liittymät ovat nimeltään Elisa Saunalahti Huoleton ja Elisa Saunalahti
          Tarkka. Kotimaan puhelut ja tekstiviestit sisältyvät
          Huoleton-liittymien kuukausimaksuun. Niiden yhteydessä ei tarvitse
          ostaa puhelinta. Tarkka-liittymissä on kuukausimaksu, mutta muuten
          niistä veloitetaan muuten käytön mukaan.
          <a href="https://elisa.fi/kauppa/puhelinliittymat">
            Katso Elisan puhelinliittymä
          </a>
        </TextArea>
        <Header4>Telia</Header4>
        <OperatorImage imgName={"teliaoperaattori.jpg"} />
        <TextArea>
          <b>Telia Finland</b> (ennen Sonera ja TeliaSonera) on Telia Company
          -yrityksen Suomen maayhtiö. Telia Company on monikansallinen
          operaattori, joka sai nykyisen muotonsa ruotsalaisen Telian ja
          suomalaisen Soneran fuusioiduttua. Telia tarjoaa kuluttajille
          pääasiassa rajattomia liittymiä Rajaton -nimellä. Niiden lisäksi
          Telian valikoimissa on uudentyyppinen Telia Dot -liittymä. Telia Dot
          -liittymässä maksetaan vain käytöstä. Datan, puheluiden ja
          tekstiviestien rajattoman käytön hinta on aina alle 25 euroa. Telia
          Dot -sovellus asennetaan puhelimeen, jonka jälkeen liittymä voidaan
          tilata sovelluksella. Ensimmäisten 30 päivän aikana peruskäyttö on
          maksutonta.
          <a href="https://kauppa.telia.fi/yksityisille/tuotteet/puhelinliittymat.aspx">
            Katso Telian puhelinliittymät
          </a>
        </TextArea>
        <Header4>DNA</Header4>
        <OperatorImage imgName={"dnaoperaattori.jpg"} />
        <TextArea>
          DNA aloitti operaattorina vuonna 2001. Se on myös Suomen suurin
          kaapelioperaattori. Norjalainen Telenor omistaa suurimman osan DNA:n
          osakkeista. DNA:n konserniin kuuluu myös DNA Kauppa. DNA Kauppa on
          kirjoitushetkellä Suomen laajin matkapuhelimien myyntiin keskittyvä
          ketju. Omnitele-yhtiö tutki vuonna 2020 matkapuhelinliittymien
          nopeuksia DNA:n pyynnöstä, ja tutkimuksen perusteella juuri DNA:n
          verkko oli keskimääräisesti nopein. DNA on kuitenkin yksi Omnitelen
          omistajista, joten tutkimukseen kannattaa suhtautua varauksella. DNA
          tarjoaa sekä rajattomia puhelinliittymiä että käytön mukaan
          veloitettavia <b>Jämpti-liittymiä</b>.
          <a href="https://www.dna.fi/puhe">Katso DNA:n puhelinliittymät</a>
        </TextArea>
        <Header4>Moi mobiili ja Globotel</Header4>
        <OperatorImage imgName={"moioperaattori.jpg"} />
        <TextArea>
          Moi Mobiili on DNA:n omistama palveluoperaattori, joka toimi ennen
          DNA:n omistusta niin sanottuna virtuaalioperaattorina. Tällöin Moi
          Mobiili vuokrasi verkon DNA:lta, sillä se ei omistanut omaa
          matkapuhelinverkkoa. Moi Mobiili on tullut tutuksi Suomen halvimmilla
          matkapuhelinliittymillä, jotka maksavat kirjoitushetkellä kuusi euroa
          kuukaudessa.
          <a href="https://www.moi.fi/">Katso Moi mobiilinn puhelinliittymät</a>
          Globetel puolestaan on suomalainen palveluoperaattori, joka toimii
          Telian matkapuhelinverkossa. Globetel laskuttaa puheluista alkavalta
          minuutilta, toisin kuin muut, jotka laskuttavat sekuntiperusteisesti.
          Globetelin yksi valttikortti muihin operaattoreihin on matalat
          ulkomaanpuheluiden hinnat.
          <a href="https://globetel.fi/">Katso Globetellin puhelinliittymät</a>
        </TextArea>
        <Header3>Miksi kilpailuttaa puhelinliittymä?</Header3>
        <TextArea>
          Puhelinliittymien kilpailuttaminen kannattaa paitsi mahdollisesti
          edullisemman hinnan vuoksi, myös siksi että samaan hintaan voi saada
          tärkeitä lisäominaisuuksia. Operaattorit kilpailevat asiakkaista,
          joten niillä on varaa usein tarjota korkeampia nopeuksia tai
          maksutonta puheaikaa, jos kilpailijan liittymä on muuten
          samanhintainen. Kilpailuttamalla voit myös huomata, mistä asioista
          maksat liittymässä. Samalla näet oletko maksanut joistakin
          ominaisuuksista aivan turhaan, jos et käytä niitä lainkaan tai varsin
          vähän. Kilpailutus on tehty helpoksi ja nopeaksi. Helpoiten se
          onnistuu vertailusivustoilla.
        </TextArea>
        <Header3>Mistä liittymien hinnat koostuvat?</Header3>
        <TextArea>
          Liittymien hinnat koostuvat monesta eri tekijästä, joista toiset
          voivat olla sinulle vähemmän tärkeitä kuin toiset. Datasiirron hinta
          on näinä päivinä yleisesti ottaen suurin tekijä, jonka tärkeys
          kannattaa miettiä etukäteen. Jos lataat kuvia ja videoita vähän sekä
          pääset usein maksuttoman ja langattoman WiFi:n ääreen, ei tällöin
          kannata ottaa liittymää jossa datasiirto on tärkein elementti.
          Puhelujen sekä tekstiviestien hinnat ovat yksi tekijä, jossa
          tekstiviestien hinta ei nykyään ole kovinkaan ratkaisevaa.
          Pikaviestisovellukset ovat korvanneet tekstiviestien käyttöä, joten
          tekstareiden lähettäminen on vähentynyt ratkaisevasti. Useimmissa
          liittymissä on kiinteä kuukausimaksu, joten tämä määrittelee pitkälle
          liittymän hintaa. Kannattaa kuitenkin tarkistaa mitä kuukausimaksuun
          kuuluu, sillä se ei välttämättä riitä juuri sinun tarpeisiisi.
          Avausmaksut eivät ole nykyään kovin tärkeä elementti, sillä
          kertasuoritteiset avausmaksut ovat yleensä pienet.
        </TextArea>
        <Header2>Yhteenveto liittymävertailusta</Header2>
        <TextArea>
          Puhelinliittymien kilpailutus ja vertailu on helpoimmillaan myös
          varsin nopeaa, jos kuluttajalla on selvä käsitys siitä, minkälaiseen
          käyttöön liittymä on tulossa. Kilpailutus ja vertailu on tärkeää myös
          silloin, kun et ole aivan selvillä mistä tällä hetkellä maksat
          nykyisessä puhelinliittymässäsi. Nykyään ei ole olemassa enää
          liittymiä, jotka pakottaisivat sinut pysymään tietyn operaattorin
          asiakkaana, joten voit kilpailuttaa ja vaihtaa liittymiä niin usein
          kuin haluat. Kilpailutus usein kannattaakin, sillä operaattorit
          muuttavat ja vaihtavat liittymiään kohtuullisen usein.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson {
      nodes {
        id
        benefits
        callPrice
        internet
        internetSpeed
        name
        price
        type
        url
        textPrice
        img
        openFee
      }
    }
  }
`

export default FrontPage
